import $ from 'jquery';

export default () => {
    var $navContainer = $('.menu--mega');
    var $navItems = $('.nav-item');
    var $backdrop = $('.mega-menu__backdrop');
    var $megaMenus = $('.mega-menu');

    let isMenuOpen = false;
    let closeTimeout;

    function closeMegaMenu() {
        $('body').removeClass('mega-menu--open');
        $backdrop.stop(true, true).fadeOut(300);
        $megaMenus.stop(true, true).fadeOut(300);
        isMenuOpen = false;
    }

    $navItems.hover(
        function () {
            clearTimeout(closeTimeout);

            var $currentNavItem = $(this);
            var $currentMegaMenu = $currentNavItem.find('.mega-menu');

            $navItems.not($currentNavItem).addClass('nav-element--inactive');

            if (!$currentMegaMenu.length) {
                closeMegaMenu();
                return;
            }

            if (isMenuOpen) {
                $megaMenus.hide();
                $currentMegaMenu.show();
            } else {
                $megaMenus.stop(true, true).fadeOut();
                $currentMegaMenu.stop(true, true).fadeIn();
                isMenuOpen = true;
            }

            $currentNavItem.addClass('active-menu');
            $backdrop.stop(true, true).fadeIn();
            $('body').addClass('mega-menu--open');

            // Mega Menü Height Calc
            var t = 0;
            var childElem = $(this).closest('.nav-menu').find('.dropdown');

            $(childElem).each(function () {
                var elem = $(this);
                elem.height('auto');
                if (elem.height() > t) {
                    t = elem.height();
                }
            });
            $(childElem).height(t);
        },
        function () {
            $navItems.removeClass('nav-element--inactive');
        }
    );

    // **Neue Logik: Mausbewegung nur unterhalb & seitlich tracken**
    $(document).on('mousemove', function (e) {
        $megaMenus.each(function () {
            var $menu = $(this);
            var $outer = $menu.find('.mega-menu__outer');

            if ($menu.is(':visible')) {
                var offset = $outer.offset();
                var width = $outer.outerWidth();
                var height = $outer.outerHeight();

                // **ÄNDERUNG:** Mausbewegung oberhalb ignorieren!
                if (e.pageY < offset.top) {
                    return;
                }

                var isOutside =
                    e.pageX < offset.left ||
                    e.pageX > offset.left + width ||
                    e.pageY > offset.top + height; // Nur unterhalb tracken

                if (isOutside) {
                    clearTimeout(closeTimeout);
                    closeTimeout = setTimeout(closeMegaMenu, 200);
                }
            }
        });
    });

    $('.dropdown-menu > li').hover(
        function () {
            $(this).addClass('active-hover').siblings().addClass('submenu--inactive');
        },
        function () {
            $(this).removeClass('active-hover').siblings().removeClass('submenu--inactive');
        }
    );
};
