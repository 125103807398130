// @ts-ignore
import baguetteBox from 'baguettebox.js';
import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

export default () => {
  var wlySwiper = new Swiper('.swiper-container-wly', {
    modules: [Navigation, Pagination, Autoplay],
    // Optional parameters
    direction: 'horizontal',
    loop: true,
    speed: 1400,
    spaceBetween: 30,
    autoplay: {
      delay: 7000,
    },
    grabCursor: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  });


  var wlySwiper = new Swiper('.swiper-container-image-text', {
    modules: [Navigation, Pagination, Autoplay],
    // Optional parameters
    direction: 'horizontal',
    loop: true,
    speed: 1400,
    spaceBetween: 30,
    autoplay: {
      delay: 7000,
    },
    grabCursor: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  });

  var wlySwiper = new Swiper('.swiper-container-testimonial', {
    modules: [Navigation, Autoplay],
    // Optional parameters
    direction: 'horizontal',
    speed: 1400,
    loop: true,
    slidesPerView: 1,
    spaceBetween: 30,
    breakpoints: {
      993: {
        slidesPerView: 2,
        spaceBetween: 50,
      }
    },
    autoplay: {
      delay: 7000,
    },
    grabCursor: false,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });

  var wlySwiper = new Swiper('.swiper-container-teaserboxes', {
    modules: [Navigation, Autoplay],
    // Optional parameters
    direction: 'horizontal',
    loop: true,
    speed: 1400,
    slidesPerView: 1,
    spaceBetween: 30,
    breakpoints: {
      993: {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 40,
      }
    },
    autoplay: {
      delay: 7000,
    },
    grabCursor: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });
};


