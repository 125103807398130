import $ from 'jquery';

export default (container?: HTMLElement) => {
  // Akkordeon
  $('.accordeon--title').click(function () {
    $(this).parent().toggleClass('active');
    if ($(this).hasClass("no--closer")) {
      $(this).siblings('.accordeon--content').slideToggle();
    } else {
      $(this).parent().siblings('.accordeon--item').removeClass('active');
      $('.accordeon--item').each(function () {
        if ($(this).hasClass('active')) {
          $(this).children('.accordeon--content').slideDown();
        } else {
          $(this).removeClass('active');
          $(this).children('.accordeon--content').slideUp();
        }
      });
    }
  });
};
