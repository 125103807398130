import $ from 'jquery';
import { animateBorder } from '@/js/scripts/animated-border';

export default () => {
  // Make fixed Navigation
  let previousScroll = 0;
  let scrollThreshold = 250;

  const parallaxContainers = $('.paralaxer');
  const hoverBorders = $('.hover--border');
  const hoveredItems = $('[class*="hovered"]');

  window.addEventListener(
    'scroll',
    (e) => {
      const currentScroll = $(e.target).scrollTop();

      if (currentScroll > scrollThreshold) {
        document.body.classList.add('fixed-header');

        if (currentScroll > previousScroll) {
          document.body.classList.add('fixed-header-away');
        } else {
          document.body.classList.remove('fixed-header-away');
        }
      } else {
        document.body.classList.remove('fixed-header', 'fixed-header-away');
      }

      // Gehe jedes Element durch, das parallax bewegen soll
      parallaxContainers.each(function () {
        var $this = $(this);

        // Hole den parallax-scroller Wert aus dem Attribut
        var parallaxSpeed = parseFloat($this.closest('[parallax-scroller]').attr('parallax-scroller'));

        // Wenn der parallax-scroller Wert existiert
        if (parallaxSpeed) {
          // Position des Elements relativ zum Dokument
          var elementOffset = $this.closest('.block').offset().top;

          // Berechne, ob das Element im Sichtbereich des Fensters ist
          if (currentScroll + $(window).height() > elementOffset && currentScroll < elementOffset + $this.closest('.block').outerHeight()) {
            // Berechne die Verschiebung basierend auf der Scrollposition
            var parallaxMove = (currentScroll - elementOffset) * parallaxSpeed;

            // Wende die Transformation auf das Element an
            $this.css('transform', 'translateY(' + parallaxMove + 'px)');
          }
        }
      });

      previousScroll = currentScroll;
    },
    {
      passive: true,
    }
  );

  // Open locale Switcher
  $('.locale-switcher').click(function(event) {
    $(this).toggleClass('active');
    event.stopPropagation();
  });

  $(document).click(function() {
    $('.locale-switcher').removeClass('active');
  });


  // Youtube Player Hover deactivate
  $('.wly-youtube__inner').click(function() {
    $(this).addClass('hover-deactivate');
  });

  // Image Accordeon
  $('.image-accordeon__item').click(function () {
    $(this).addClass('active--yes');
    $(this).siblings().removeClass('active--yes');
  });

  hoverBorders.each(function () {
    animateBorder(this);
  });

  // Tabs Module Active
  $('.tabs__wrapper').each(function() {
    $(this).find('.button').first().addClass('active');
  });

  $('.tabs__wrapper .button').click(function() {
    $(this).siblings('.button').removeClass('active');
    $(this).addClass('active');
    $(this).closest('.tabs__inner').find('.tabs__links')
  });


  // Team Height Calc
  function teamHeightCalc() {
    $('.t-heigter').each(function () {
      var memberHeight = $(this).find('.t__content').outerHeight();
      $(this).find('.t-heigter__wrapper').css('padding-bottom', memberHeight);
    });
  }

  teamHeightCalc();

  window.addEventListener('resize', () => teamHeightCalc(), { passive: true });

  // Navigation Touch Geräte
  $('#headerNavbar ul.navbar-nav > li.nav-menu > a').on('touchend', function (e) {
    if (!$(this).parent().hasClass('hovered')) {
      e.preventDefault();
      $(this).parent().toggleClass('hovered');
      $(this).parent().siblings().removeClass('hovered');
    }
  });

  hoveredItems.each(function () {
    $(this).removeClass('.hovered');
  });
};
